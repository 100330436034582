<template>
  <div class="customer-referral">
    <div class="banner">
      <div class="container">
        <strong>{{ $t(`CustomerReferral['客户推荐']`) }}</strong>
        <p>{{ $t(`CustomerReferral['CUSTOMER RECOMMENDATION']`) }}</p>
      </div>
    </div>
    <Penel :title="$t(`CustomerReferral['客户推荐-']`)" class="referral">
      <ul>
        <li v-for="item in 4" :key="item">
          <div class="item-box">
            <strong>执行长 | Mr.Brown </strong>
            <p>
              客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话客户推荐的话
            </p>
          </div>
        </li>
      </ul>
    </Penel>
    <Penel :title="$t(`CustomerReferral['客户推荐影片']`)" class="film">
      <ul>
        <li v-for="item in 8" :key="item">
          <img src="../assets/image/default.png" alt="" />
          <p>客户推荐的影片名字</p>
        </li>
      </ul>
    </Penel>
  </div>
</template>

<script>
export default {
  name: 'CustomerReferral'
}
</script>

<style lang="less" scoped>
.customer-referral {
  .banner {
    height: 550px;
    background: url('../assets/image/customerReferral/banner@2x.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding-top: 230px;
      padding-left: 110px;
      color: #ffffff;
      strong {
        font-size: 48px;
        line-height: 1.3;
        font-weight: bold;
      }
      p {
        font-size: 21px;
        font-weight: 300;
        color: #ffffff;
        line-height: 3;
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        padding: 80px 18px 0;
        text-align: center;
        strong {
          font-size: 36px;
        }
        p {
          font-size: 16px;
        }
      }
      height: 400px;
    }
  }
  .referral {
    padding-bottom: 0;
    li {
      max-width: 1020px;
      box-sizing: border-box;
      margin: 0 auto 20px;
      border-width: 41px 30px 30px 50px;
      border-style: solid;
      border-image: url('../assets/image/customerReferral/对话框@2x.png') 82 60
        60 100;
      .item-box {
        margin-top: -12px;
        background: #f7f7f7;
        font-size: 14px;
        strong {
          width: 120px;
          font-weight: bold;
          color: #333333;
        }
        p {
          margin-top: 5px;
          line-height: 1.3;
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin-left: -15px;
      }
    }
  }
  .film {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 260px;
        margin: 0 10px 38px;
        img {
          width: 260px;
          height: 156px;
          background: #f7f7f7;
          border-radius: 6px;
        }
        p {
          margin-top: 15px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 1.3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    @media screen and (max-width: 992px) {
      ul {
        margin: 0 -18px;
        li {
          width: 160px;
          margin: 0 10px 30px;
          img {
            width: 160px;
            height: 96px;
          }
        }
      }
    }
  }
}
</style>
